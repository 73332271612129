@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ivory;
}

canvas {
  touch-action: none;
}

.htmlScreen iframe {
  width: 1024px;
  height: 670px;
  border: none;
  border-radius: 20px;
  background: #000000;
}

.blink {
  padding: 0px;
  text-align: center;
  line-height: 0px;
  background-color: black;
  animation: blink 1s ease-out infinite;
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.scroll::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
.scroll::-webkit-scrollbar-track {
  background: #ffffff00;
  border-radius: 15px;
}

/* Handle */
.scroll::-webkit-scrollbar-thumb {
  border-radius: 15px;
  background: rgb(0, 0, 0, 0.5);
}
